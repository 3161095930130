<template>
  <van-search
    v-model="keyword"
    show-action
    placeholder="请输入客户的名字"
    @search="onSearch"
  >
    <template #action>
      <div @click="onSearch">搜索</div>
    </template>
  </van-search>

  <div class="user" v-for="user in list" :key="user.external_userid">
    <van-cell
      center
      is-link
      :to="{
        path: '/customer-portrait',
        query: { external_userid: user.external_userid }
      }"
      :border="false"
    >
      <template #title>
        <div class="user-info">
          <img
            class="user-avater"
            :src="
              user.img
                ? user.img
                : 'https://rescdn.qqmail.com/node/wwmng/wwmng/style/images/independent/DefaultAvatar$73ba92b5.png'
            "
          />
          <div style="padding-left: 10px">
            <p class="user-name">
              {{ user.name }}
              <span class="user-company">
                {{ user.customer_type == 1 ? '@微信' : '@企业微信' }}
              </span>
            </p>
            <p class="user-addTime">添加时间：{{ user.add_time }}</p>
          </div>
        </div>
      </template>
    </van-cell>
    <p class="user-history">
      {{ user.other_info.name }}：{{ user.other_info.value }}
    </p>
    <ul class="user-label">
      <van-tag
        v-for="label in user.label"
        :key="label"
        color="#F5F5F5"
        text-color="#5C5C5C"
      >
        {{ label }}
      </van-tag>
    </ul>
    <van-button
      class="user-btn"
      type="primary"
      block
      round
      @click="onOpenChat(user)"
    >
      联系客户
    </van-button>
  </div>
</template>

<script>
import { Search, Cell, Tag, Button } from 'vant'
import { reactive, toRefs } from 'vue'
import { getLabelType } from '@/service/customer.service.js'
import { wechatConfig } from '@/utils/wechat.js'
import { getCustomerList } from '@/service/customer.service.js'

export default {
  name: 'Label',
  components: {
    [Search.name]: Search,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Button.name]: Button
  },
  setup() {
    const state = reactive({
      keyword: '',
      list: []
    })
    const onSearch = async () => {
      const { data } = await getCustomerList({
        keyword: state.keyword,
        page: 1,
        page_size: 100
      })
      state.list = data.list
    }

    //打开会话
    const onOpenChat = (user) => {
      let obj = {
        externalUserIds: user.external_userid
      }
      if (
        localStorage.getItem('chain_corp_id') &&
        localStorage.getItem('chain_corp_id') != '0'
      ) {
        console.log('createCorpGroupChatparams:', obj)
        createCorpGroupChat(obj)
      } else {
        obj.ChatId = ''
        openEnterpriseChat(obj)
      }
      const params = {
        externalUserIds: user.external_userid
      }
      // wechatConfig((wx) => {
      //   wx.openEnterpriseChat(
      //     Object.assign(params, {
      //       chatId: '',
      //       success: function (res) {
      //         console.log(res)
      //       },
      //       fail: function (res) {
      //         if (res.errMsg.indexOf('function not exist') > -1) {
      //           alert('版本过低请升级')
      //         }
      //       }
      //     })
      //   )
      // })
    }

    return { ...toRefs(state), onSearch, onOpenChat }
  }
}
</script>

<style lang="less" scoped>
.user {
  background-color: #fff;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-avater {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  &-history,
  &-addTime {
    color: #999;
    font-size: 12px;
  }
  &-history,
  &-label {
    padding-bottom: 5px;
  }
  &-label > .van-tag {
    margin-right: 10px;
  }
  &-company {
    color: #07c160;
  }
  &-btn {
    height: 30px;
    margin: 5px auto;
  }
}
</style>
